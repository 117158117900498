import { BackendRes, PaginationResult } from '@/common/types';

export const transformTableResponse = <T>(
  response?: BackendRes<{
    result: PaginationResult<T>;
  }>
) => {
  if (!response)
    return {
      tableData: null,
      pagination: {
        current: 1,
        currentPageSize: 0,
        pageSize: 10,
        total: 0,
        totalPages: 0,
        showQuickJumper: false,
      },
    };

  const data = response?.data?.result;
  const pagination = {
    current: data.page,
    currentPageSize: data.docs.length,
    pageSize: data.limit,
    total: data.totalDocs,
    totalPages: data.totalPages,
    showQuickJumper: false,
  };
  return { tableData: data?.docs, pagination };
};

export const determineColor = (rank, transparentBackground = false, source) => {
  let tagColor;

  if (source === 'applicant-list') {
    switch (rank) {
      case 'top1%':
        tagColor = 'green-rank';
        break;
      case 'top5%':
        tagColor = 'pink-rank';
        break;
      case 'top10%':
        tagColor = 'orange-rank';
        break;
      case 'top25%':
        tagColor = 'blue-rank';
        break;
      default:
        tagColor = 'gray-rank';
        break;
    }
    return tagColor;
  }

  switch (rank) {
    case 'top1%':
      tagColor = 'green';
      break;
    case 'top5%':
      tagColor = 'pink';
      break;
    case 'top10%':
      tagColor = 'orange';
      break;
    case 'top25%':
      tagColor = 'blue';
      break;
    default:
      tagColor = 'gray';
      break;
  }

  if (!transparentBackground) tagColor = `${tagColor}--alt`;
  return tagColor;
};
