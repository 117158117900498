const ClockIcon = (props: JSX.IntrinsicElements['svg']) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="text-violet-600"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 11C3.24 11 1 8.765 1 6C1 3.24 3.24 1 6 1C8.765 1 11 3.24 11 6C11 8.765 8.765 11 6 11ZM7.595 7.855C7.655 7.89 7.72 7.91 7.79 7.91C7.915 7.91 8.04 7.845 8.11 7.725C8.215 7.55 8.16 7.32 7.98 7.21L6.2 6.15V3.84C6.2 3.63 6.03 3.465 5.825 3.465C5.62 3.465 5.45 3.63 5.45 3.84V6.365C5.45 6.495 5.52 6.615 5.635 6.685L7.595 7.855Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ClockIcon;
