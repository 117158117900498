const RankIcon = ({ className }) => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} size-[12px]`}
    >
      <path
        d="M13.125 1.9375H12V1.375C11.9997 1.07672 11.8811 0.790748 11.6702 0.579834C11.4593 0.36892 11.1733 0.250298 10.875 0.25H4.125C3.82672 0.250298 3.54075 0.36892 3.32983 0.579834C3.11892 0.790748 3.0003 1.07672 3 1.375V1.9375H1.875C1.57672 1.9378 1.29075 2.05642 1.07983 2.26733C0.86892 2.47825 0.750298 2.76422 0.75 3.0625V4.75C0.750744 5.34651 0.988036 5.91837 1.40983 6.34017C1.83163 6.76196 2.40349 6.99926 3 7H3.18112C3.41552 7.86103 3.89556 8.63517 4.56261 9.22791C5.22967 9.82065 6.05489 10.2063 6.9375 10.3379V12.625H4.125V13.75H10.875V12.625H8.0625V10.3362C8.95657 10.2257 9.79657 9.8482 10.4729 9.25307C11.1492 8.65793 11.6304 7.87276 11.8538 7H12C12.5965 6.99926 13.1684 6.76196 13.5902 6.34017C14.012 5.91837 14.2493 5.34651 14.25 4.75V3.0625C14.2497 2.76422 14.1311 2.47825 13.9202 2.26733C13.7093 2.05642 13.4233 1.9378 13.125 1.9375ZM3 5.875C2.70172 5.8747 2.41575 5.75608 2.20483 5.54517C1.99392 5.33425 1.8753 5.04828 1.875 4.75V3.0625H3V5.875ZM13.125 4.75C13.1247 5.04828 13.0061 5.33425 12.7952 5.54517C12.5843 5.75608 12.2983 5.8747 12 5.875V3.0625H13.125V4.75Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default RankIcon;
