import { AxiosInstance, AxiosRequestConfig } from 'axios';
import clsx from 'clsx';
import qs from 'qs';

import Tag from '@/common/components/Tag';
import { BackendRes, FixMeLater, PaginationResult } from '@/common/types';
import { paramsSerializer } from '@/common/utils/formatter';
import { determineColor } from '@/common/utils/table';
import ClockIcon from '@/icons/ClockIcon';
import { apiInstance } from '@/modules/core/axios/configs';

/**
 * @typedef {import('axios').AxiosInstance} AxiosInstance
 * @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig
 */
import { ApplicationStatus, RANK_LIST } from '../constants';
import { Job } from '../types';

export type PutBulkStatusApplicantsParams = {
  axiosConfig?: AxiosRequestConfig;
  query: {
    jobId: string;
  };
  payload: {
    status: ApplicationStatus;
    recruitmentStepId?: string;
    jobApplicationIds: string[];
    notify?: boolean;
    note?: string;
    rejectedReasonType?:
      | 'notQualified'
      | 'salaryDoesNotMatch'
      | 'others'
      | null;
    customRejectedReason?: string;
  };
};

export type PostBulkMoveApplicantsParams = {
  axiosConfig?: AxiosRequestConfig;
  query: {};
  payload: {
    jobId: string;
    jobApplicationIds: string[];
    status: ApplicationStatus;
    recruitmentStepId: string;
    notify: boolean;
    email?: {
      subject: string;
      body: string;
    };
  };
};

const JobService = {
  _getApplicantCursor: (instance, { jobId, candidateId, param }) => {
    const key = `/v1/job-portal/job/${jobId}/candidate/${candidateId}/cursor?${paramsSerializer(
      param
    )}`;

    const fetcher = async () => {
      if (!param.status) delete param.status;
      if (!param.sortParam) delete param.sortParam;
      if (!param.sortDir) delete param.sortDir;

      const { data } = await instance.get(key);
      return data?.data?.result;
    };

    return { key, fetcher };
  },

  _getApplicantMatchedPercentage: (instance, id) => {
    const key = `/v1/job-portal/application/${id}/match-percentage`;

    const fetcher = async (key) => {
      try {
        const { data } = await instance.get(key);

        return data?.data?.result;
      } catch (error) {
        console.error(error);
      }
    };

    return { key, fetcher };
  },

  _getCandidatesCount: (
    instance: AxiosInstance,
    params: {
      id: string | undefined;
      groupField: string | undefined;
    }
  ) => {
    const key = `/v1/job-portal/job/${params?.id}/candidate/count?groupField=${params?.groupField}`;

    const fetcher = async (params: Record<string, string>) => {
      const res = await instance.get<
        BackendRes<{
          result: PaginationResult<FixMeLater>;
        }>
      >(key, {
        params,
        paramsSerializer,
      });

      return res?.data;
    };

    return { key, fetcher };
  },

  _getCandidatesRange: (instance, { id, type }) => {
    const key = `/v1/job-portal/job/${id}/candidate-range?type=${type}`;

    const fetcher = async (params) => {
      const res = await instance.get(key, {
        params,
        paramsSerializer,
      });

      return res?.data;
    };

    return { key, fetcher };
  },

  _getDepartments: (instance, { params }) => {
    const key = `/v1/job-portal/department`;

    const fetcher = async (key) => {
      try {
        const { data } = await instance.get(key, {
          params: {
            ...params,
          },
        });
        return data?.data?.result;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    return { key, fetcher };
  },

  _getJobCampuses: (instance, id) => {
    const key = `/v1/job-portal/job/${id}/university`;

    const fetcher = async (params) => {
      const res = await instance.get(key, {
        params,
        paramsSerializer,
      });

      return res?.data;
    };

    return { key, fetcher };
  },

  _getJobExperiences: (instance, id) => {
    const key = `/v1/job-portal/job/${id}/applied-eligibility`;

    const fetcher = async (params) => {
      const res = await instance.get(key, {
        params: { ...params },
        paramsSerializer,
      });

      return res?.data;
    };

    return { key, fetcher };
  },

  _getJobRanks: (instance, jobId) => {
    const key = `/v1/job-portal/job/${jobId}/student-rank`;

    const fetcher = async (params) => {
      const res = await instance.get(key, {
        params: { ...params, useCounter: true },
        paramsSerializer,
      });

      return res?.data;
    };

    return { key, fetcher };
  },

  _getJobRecruitmentStepStats: (instance, params) => {
    const serializedParams = qs.stringify(params, { arrayFormat: 'brackets' });

    const key = `/v1/job-portal/job/recruitment-step-stats?` + serializedParams;

    const fetcher = async () => {
      try {
        const response = await instance.get(key);

        return response.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    };

    return { key, fetcher };
  },

  _getRecruitmentStep: (instance, recruitmentStepId) => {
    const key = `/v1/job-portal/recruitment-step/${recruitmentStepId}`;
    const fetcher = async (key) => {
      if (!recruitmentStepId || recruitmentStepId === undefined) {
        throw new Error('ID is not ready');
      }

      try {
        const { data } = await instance.get(key);
        return data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    };
    return { key, fetcher };
  },

  _getTalentApplication: (instance, candidateId) => {
    const key = `/v1/job-portal/application/${candidateId}`;

    const fetcher = async (key) => {
      if (!candidateId) throw new Error('ID is not ready');

      try {
        const { data } = await instance.get(key);

        return data?.data?.result;
      } catch (error) {
        console.log(error);
        throw error;
      }
    };

    return { key, fetcher };
  },

  _getTalentAssessment: (instance, techTestId) => {
    const key = `/v1/job-portal/technical-test/${techTestId}`;
    const fetcher = async (key) => {
      if (!techTestId || techTestId === undefined) {
        throw new Error('ID is not ready');
      }

      try {
        const { data } = await instance.get(key);
        return data?.data?.result;
      } catch (error) {
        console.log(error);
        throw error;
      }
    };
    return { key, fetcher };
  },

  _getTalentProcessHistory: (instance, candidateId) => {
    const key = `/v1/job-portal/application/${candidateId}/process-history`;
    const fetcher = async (key) => {
      if (!candidateId) throw new Error('ID is not ready');
      try {
        const { data } = await instance.get(key);
        return data?.data?.result;
      } catch (error) {
        console.error(error);
      }
    };

    return { key, fetcher };
  },

  createIndustryPreference: (instance, name) => {
    if (!name) return false;
    return instance.post('/v1/job-portal/industry', {
      name,
    });
  },

  createJobPost: (instance, data) => {
    return instance.post('/v1/job-portal/job', {
      ...data,
    });
  },

  createJobRole: (instance, data) => {
    return instance.post('/v1/job-portal/job-role', {
      ...data,
    });
  },

  createSkill: (instance, name) => {
    if (!name) return false;
    return instance.post('/v1/job-portal/skill', {
      name,
    });
  },

  deleteJobPost: (instance, id) => {
    return instance.delete(`/v1/job-portal/job/${id}`);
  },

  fetchJobPostDetail: (instance, id) => {
    const key = `/v1/job-portal/job/${id}`;
    const fetcher = async () => {
      const res = await instance.get(key);
      return res?.data;
    };
    return {
      key,
      fetcher,
    };
  },

  generateLastEducationsData: (data) => {
    if (data.includes(8)) {
      const getEduIndex = data.findIndex((list) => list === 8);

      data.splice(getEduIndex, 1);

      data.push(1, 2);
    }

    if (data.includes(9)) {
      const getEduIndex = data.findIndex((list) => list === 9);

      data.splice(getEduIndex, 1);

      data.push(3, 4);
    }

    return data;
  },

  getActiveJobPosts: (
    instance,
    companyId,
    page = 1,
    limit = 10,
    query = null,
    sortDir = 'desc'
  ) => {
    const params = new URLSearchParams({
      companyId,
      page,
      limit,
      sortBy: sortDir,
    } as unknown as Record<string, string>);

    if (query) params.append('search', query);
    return instance.get(`/v1/job-portal/employer/job?${params}`);
  },

  getAllJobPost: ({
    authorId,
    companyId,
    filter = {},
    instance,
    jobType,
    limit = 10,
    page = 1,
    reviewerEmail,
    search,
    sortDir = 'desc',
    sortParam = 'updatedAt',
  }: {
    authorId?: string;
    companyId?: string;
    filter?: Record<string, FixMeLater>;
    instance: AxiosInstance;
    jobType?: string;
    limit?: number;
    page?: number;
    reviewerEmail?: number;
    search?: number;
    sortDir?: string;
    sortParam?: string;
  }): [
    string,
    () => Promise<
      BackendRes<{
        result: PaginationResult<Job>;
      }>
    >
  ] => {
    const filters = JSON.parse(JSON.stringify(filter));

    if (sortParam === null) sortParam = 'updatedAt';
    const params = {
      companyId,
      limit,
      page,
      search,
      sortBy: sortDir,
      ...filters,
      sortParam,
    };
    if (jobType === 'myJobs') {
      params['authorId'] = authorId;
      params['reviewerEmail'] = reviewerEmail;
    }
    // Career Fair related parameters
    params['includeCareerFair'] = true;

    const serializedParams = qs
      .stringify(
        {
          ...params,
        },
        {
          arrayFormat: 'repeat',
        }
      )
      .toString();

    const key = `/v1/job-portal/employer/job?` + `${serializedParams}`;
    const fetcher = async () => {
      try {
        const response = await instance.get<
          BackendRes<{
            result: PaginationResult<Job>;
          }>
        >(key);
        return response.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    };

    return [key, fetcher];
  },

  getApplicationDetail: ({
    axiosConfig,
  }: { axiosConfig?: AxiosRequestConfig } = {}) => {
    const { params } = axiosConfig || {};
    const { id, ...restParams } = params || {};
    return apiInstance.get(`/v1/job-portal/application/${id}`, {
      ...axiosConfig,
      params: restParams,
    });
  },

  getApplicantCVDownloadLink: (instance, id) => {
    return instance.get(`/v1/job-portal/application/${id}/download-cv`);
  },

  getCandidatesByJobId: (
    instance,
    page = 1,
    limit = 10,
    filter = {},
    sortDir,
    sortParam,
    id,
    search,
    withRecommendation
  ) => {
    // remove empty values
    const filters = JSON.parse(JSON.stringify(filter));

    // combine all params
    const params = {
      page,
      limit,
      withRecommendation,
      ...(sortDir && { sortBy: sortDir }),
      ...(sortParam && { sortParam }),
      ...(search && { search }),
      ...filters,
    };

    // stringify params
    const paramsString = qs.stringify(params, { arrayFormat: 'repeat' });

    const key = `/v1/job-portal/job/${id}/candidate?` + `${paramsString}`;
    const fetcher = async () => {
      try {
        const response = await instance.get(key);
        return response.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    };

    return {
      key,
      fetcher,
    };
  },

  getDefaultQuestions: async ({
    axiosConfig,
  }: {
    axiosConfig: AxiosRequestConfig;
  }) => {
    return apiInstance.get(`/v1/job-portal/job/default-questions`, axiosConfig);
  },

  getDepartments: (instance, params) => {
    return instance.get(`/v1/job-portal/department`, {
      params,
    });
  },

  getIndustryPreferences: (instance, limit = 10, page = 1, query) => {
    let temp = '';
    if (query) temp = `&search=${query}`;
    return instance.get(
      `/v1/job-portal/industry?sortParam=name&limit=${limit}&sortBy=desc&page=${page}${temp}`
    );
  },

  // @deprecated - use getJobList instead
  getJobPosts: (instance, params) => {
    return instance.get(`/v1/job-portal/employer/job`, {
      params: {
        ...params,
      },
    });
  },

  getJobList: async ({ axiosConfig }: { axiosConfig: AxiosRequestConfig }) => {
    return apiInstance.get(`/v1/job-portal/employer/job`, axiosConfig);
  },

  getJobPostDetail: (instance, id) => {
    return instance.get(`/v1/job-portal/job/${id}`);
  },

  getJobRoleCategories: (instance) => {
    return instance.get('/v1/job-portal/job-category');
  },

  getLastSavedJobContent: (instance, role) => {
    const params = new URLSearchParams({
      ...(role && { role: role }),
    });
    return instance.get(`/v1/job-portal/job/last-saved`, {
      params,
    });
  },

  getMajors: (instance, jobId, query, limit = 10) => {
    let temp = '';
    if (query) temp = `&majorContains=${query}`;
    return instance.get(
      `/v1/job-portal/job/${jobId}/candidate/major?limit=${limit}${temp}`
    );
  },

  getPopularJobRolesByCategoryId: (instance, id) => {
    return instance.get(`/v1/job-portal/job-category/${id}`);
  },

  getRecomTemplates: (instance, query) => {
    return instance.get(
      `/v1/job-portal/job/description-template?jobTitle=${query}`
    );
  },

  getStudentCursor: (instance, id) => {
    return instance.get(`/v1/job-portal/student/recommendation/${id}/cursor`);
  },

  getSuggestedRoles: (instance, query) => {
    return instance.get(`/v1/job-portal/job/suggested-role?search=${query}`);
  },

  getTalentAssessment: (instance, techTestId) => {
    return instance.get(`/v1/job-portal/technical-test/${techTestId}`);
  },

  getTechnicalTestLatest: (instance, techTestId) => {
    return instance.get(`/v1/job-portal/technical-test/${techTestId}`);
  },

  getUniversities: (instance, jobId, query, limit = 10) => {
    let temp = '';
    if (query) temp = `&universityContains=${query}`;
    return instance.get(
      `/v1/job-portal/job/${jobId}/candidate/university?limit=${limit}${temp}`
    );
  },

  getCandidateCountPerStatus: ({
    axiosConfig,
  }: {
    axiosConfig?: AxiosRequestConfig;
  }) => {
    const { params } = axiosConfig || {};
    const { id, ...restParams } = params || {};

    return apiInstance.get(
      `/v1/job-portal/job/${id}/candidate/count-per-status`,
      {
        ...axiosConfig,
        params: restParams,
      }
    );
  },

  inviteStudentToJob: (instance, studentId, jobIds) => {
    return instance.post(`/v1/job-portal/student/${studentId}/invite`, {
      jobIds,
    });
  },

  putBulkStatusApplicants: async ({
    axiosConfig,
    query,
    payload,
  }: PutBulkStatusApplicantsParams) => {
    return apiInstance.put(
      `/v1/job-portal/job/${query.jobId}/candidate/bulk-status`,
      payload,
      axiosConfig
    );
  },

  postBulkMoveApplicants: async ({
    axiosConfig,
    payload,
  }: PostBulkMoveApplicantsParams) => {
    return apiInstance.post(
      `/v1/job-portal/applications/bulk-move`,
      payload,
      axiosConfig
    );
  },

  rateTalentAssessment: (instance, techTestId, payload) => {
    return instance.patch(
      `/v1/job-portal/technical-test/${techTestId}/rate`,
      payload
    );
  },

  renderExperienceTag: ({ data }) => {
    if (!data) return <></>;
    return (
      <Tag
        icon={<ClockIcon />}
        text={data}
        labelClassName="text-[10px] text-violet-600 font-semibold"
        className="bg-violet-0 !px-[8px] py-[2.5px]"
      />
    );
  },

  renderRankLabel: ({
    rank,
    className,
    rankIconClassName = '',
    labelClassName = '',
    transparentBackground = false,
    acceptedWithConditions = false,
    source,
  }) => {
    if (!rank) {
      return;
    }
    if (acceptedWithConditions && rank === 'top25%') rank = 'others';

    const rankItems = RANK_LIST.filter((rankItem) => rankItem.value === rank);

    if (rankItems.length > 0) {
      return rankItems.map((rankItem) => {
        return (
          <Tag
            className={clsx('px-[12px] py-2 font-semibold', className)}
            text={rankItem.label}
            type="rank"
            color={determineColor(
              rankItem.value,
              transparentBackground,
              source
            )}
            key={`${rankItem}`}
            withIcon={rankItem.value !== 'others'}
            rankIconClassName={rankIconClassName}
            labelClassName={labelClassName}
            size="small"
          />
        );
      });
    } else {
      return (
        <Tag
          text="Pending"
          size="small"
          type="rank"
          withIcon={false}
          color={determineColor(null, transparentBackground, source)}
          className={clsx('px-[12px] py-2 font-semibold', className)}
          labelClassName={labelClassName}
        />
      );
    }
  },

  replaceLastEducationEnums: (lists) => {
    if (
      lists?.includes(1) &&
      lists?.includes(2) &&
      ((lists?.includes(3) && lists?.includes(4)) ||
        (!lists?.includes(3) && !lists?.includes(4)))
    ) {
      const removeEducationList = [1, 2];
      for (let i = removeEducationList.length - 1; i >= 0; i--) {
        const getEduIndex = lists.findIndex(
          (list) => list === removeEducationList[i]
        );

        lists.splice(getEduIndex, 1);
      }

      lists.push(8);
    }
    if (
      lists?.includes(3) &&
      lists?.includes(4) &&
      ((lists?.includes(1) && lists?.includes(2)) ||
        (!lists?.includes(1) && !lists?.includes(2)))
    ) {
      const removeEducationList = [3, 4];

      for (let i = removeEducationList.length - 1; i >= 0; i--) {
        const getEduIndex = lists.findIndex(
          (list) => list === removeEducationList[i]
        );

        lists.splice(getEduIndex, 1);
      }

      lists.push(9);
    }

    return lists;
  },

  updateRecruitmentStep: ({
    axios,
    params = {} as { recruitmentId: string },
    body = {},
    config,
  }) => {
    return axios.patch(
      `/v1/job-portal/recruitment-step/${params.recruitmentId} `,
      body,
      config
    );
  },

  updateJobPost: (instance, id, data) => {
    return instance.put(`/v1/job-portal/job/${id}`, {
      ...data,
    });
  },

  updateJobPostState: (instance, id, data) => {
    return instance.patch(`/v1/job-portal/job/${id}/published`, {
      ...data,
    });
  },

  updateJobStatus: (instance, { jobId, status }) => {
    return instance.patch(`/v1/job-portal/job/${jobId}/status`, {
      status,
    });
  },
};

export default JobService;
